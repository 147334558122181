<template>
  <div class="d-flex flex-column flex-align-center text-center bg pt-10">
    <div class="d-flex flex-column flex-align-center w-100">
      <div class="logo"></div>
      <span class="text-secondary text-bold font-size-16 mt-4">Salve seus contatos</span>
      <span class="text-primary text-bold font-size-16">com inteligência.</span>
      <form @submit.prevent="submitForm" class="signin mt-4">
        <div class="user-info">
          <InputText field="email" placeholder="Seu e-mail" :validate="v$" :required="true" type="email" bgColor="#e9e9e9" />
          <InputText field="password" placeholder="Sua senha" :validate="v$" :required="true" type="password" bgColor="#e9e9e9" />
        </div>
        <div class="d-flex flex-column flex-align-center mt-6 mb-10">
          <button type="submit" class="signin-button mt-1">
            <span class="text-bold font-size-16">
              ENTRAR
            </span>
          </button>
          <router-link to="/forgot-password" class="font-size-14 mt-5 font-weight-500 text-default text-decoration-none">Esqueci minha senha</router-link>
          <router-link to="/sign-up" class="font-size-14 mt-5 font-weight-500 text-default text-decoration-none">Não tem uma conta? Crie uma</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { mapActions, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import InputText from '@/components/InputText'
import api, { onSignin } from '@/services/api'

export default {
  name: 'SignInView',
  components: {
    InputText
  },
  data() {
    return {
      email: '',
      password: ''
    }
  },
  created() {
    if (this.currentUser) {
      this.$router.push('/connections')
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser']),
    async submitForm() {
      try {
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return

        const params = {
          email: this.v$.email.$model,
          password: this.v$.password.$model
        }

        const response = await api.auth.signIn(params)

        const { data } = response

        onSignin(response)
        this.setCurrentUser(data)
        this.$router.push('/connections')
      } catch (error) {
        console.error('Erro ao fazer login:', error)
      }
    }
  },
  setup () {
    const state = reactive({
      email: '',
      password: ''
    })
    const rules = {
      email: {
        required: helpers.withMessage('Informe seu e-mail', required),
        email: helpers.withMessage('Informe um e-mail válido', email)
      },
      password: {
        required: helpers.withMessage('Informe sua senha', required)
      }
    }

    const v$ = useVuelidate(rules, state)

    return { state, v$ }
  }
}
</script>

<style scoped>
.signin {
  width: 100%;
  max-width: 400px;
}

.bg {
  /* background: url(../../assets/images/bg.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  width: 100vw;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
}

.signin-button {
  background-color: #288288;
  color: #FFF;
  border: 0px;
  border-radius: 20px;
  padding: 10px;
  width: 40%;
  min-width: 100px;
  max-width: 250px;
  font-family: Raleway;
}

.logo {
  background: url(../../assets/logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
}
</style>