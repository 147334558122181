<template>
  <!-- <button v-on:click="logoutUser">Sair</button> -->
  <div class="content-profile">
    <ProfileRead :user="currentUser" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api, { onSignout } from '@/services/api'
import ProfileRead from '@/components/ProfileRead'
export default {
  name: 'ProfileView',
  components: {
    ProfileRead
  },
  created() {
    this.getCurrentUser()
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    ...mapActions('auth', ['logout', 'setCurrentUser']),
    getCurrentUser() {
      if (!this.currentUser) return

      const id = this.currentUser.id
      api.users.get(id).then((response) => {
        const { data:user, status } = response
        if (status === 200) this.setCurrentUser(user)
      })
    },
    logoutUser() {
      this.$router.push('/')
      this.logout({
        callback: () => {
          onSignout()
        }
      })
    }
  }
}
</script>

<style scoped>
.content-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
</style>
