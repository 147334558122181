import axios from 'axios'
import { API_URL } from '@/config'

import auth from './api/auth'
import connections from './api/connections'
import communities from './api/communities'
import users from './api/users'
import specialties from './api/specialties'
import interests from './api/interests'
import uploads from './api/uploads'

const api = axios.create({ baseURL: API_URL })

api.defaults.headers.common['access-token'] = localStorage.getItem('access-token')
api.defaults.headers.common['client'] = localStorage.getItem('client')
api.defaults.headers.common['uid'] = localStorage.getItem('uid')

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/sign-in'
    }
    return Promise.reject(error)
  }
)

export const onSignin = async({ headers }) => {
  localStorage.setItem('access-token', headers['access-token'])
  localStorage.setItem('client', headers['client'])
  localStorage.setItem('uid', headers['uid'])
  api.defaults.headers.common['access-token'] = headers['access-token']
  api.defaults.headers.common['client'] = headers['client']
  api.defaults.headers.common['uid'] = headers['uid']
}

export const onSignout = async () => {
  localStorage.removeItem('access-token')
  localStorage.removeItem('client')
  localStorage.removeItem('uid')
  api.defaults.headers.common = {}
}

export default {
  auth: auth(api),
  connections: connections(api),
  communities: communities(api),
  users: users(api),
  specialties: specialties(api),
  interests: interests(api),
  uploads: uploads(api)
}
