<template>
  <div class="input-wrapper">
    <input
      :type="type"
      v-model="v[field].$model"
      :class="{ error: v[field].$errors.length }"
      :style="inputStyle"
    />
    <span class="input-placeholder" v-if="!v[field].$model" v-text="placeholder"></span>
  </div>
  <span
    class="text-white text-bold font-size-12 mt-2 text-error"
    v-if="v[field].$errors.length"
  >
    {{ v[field].$errors[0]?.$message }}
  </span>
</template>

<script scoped>
export default {
  name: 'InputText',
  props: {
    field: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    validate: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    bgColor: {
      type: String,
      default: '#CCC'
    }
  },
  created () {
    this.v = this.validate
  },
  computed: {
    inputStyle () {
      const color = this.bgColor === '#1a2c43' ? '#FFF' : '#1e1e1e'
      return {
        backgroundColor: this.bgColor,
        color
      }
    }
  }
}
</script>

<style scoped>
.input-wrapper {
  position: relative;
  margin-top: 10px;
  border-radius: 20px;
}

.input-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #949494;
  pointer-events: none;
  width: 100%;
}

.input-wrapper input {
  line-height: 20px;
  border: 0px;
  border-radius: 20px;
  padding: 10px;
  width: 95%;
  max-width: 300px;
  text-align: center;
  font-family: 'Raleway';
  font-size: 16px;
  opacity: 0.7;
}

.text-error {
  color: #ff0000;
}
</style>