<template>
  <div class="d-flex flex-column flex-align-center text-center bg-subscribe mt-10">
    <div class="d-flex flex-column flex-align-center w-100 card">
      <div class="d-flex flex-justify-start header-profile">
        <div>
          <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload">
          <img v-if="avatar" :alt="user.name" :src="avatar" class="user-avatar" @click="handleAvatarClick">
        </div>
        <div class="d-flex flex-column flex-justify-center flex-align-start">
          <span class="user-name text-bold font-size-24">{{ user.name }}</span>
          <span class="font-size-16 font-weight-500">{{ user.instagram?.slice(0, 20) }}</span>
        </div>
      </div>
      <form @submit.prevent="save" class="mt-4 gradient-top">
        <div class="user-info">
          <span class="text-bold font-size-16 mt-2">
            Informações pessoais
          </span>
          <InputText field="name" placeholder="Seu nome completo" :validate="v$" :required="true" type="text" />
          <InputText field="email" placeholder="Seu e-mail" :validate="v$" :required="true" type="email" />
          <InputText field="instagram" placeholder="Seu @ no Instagram" :validate="v$" :required="true" type="text" :onPress="formatInstagram" />
        </div>

        <div class="specialties">
          <div>
            <span class="font-size-16 mt-2">
              Suas Principais
            </span>
            <span class="text-bold font-size-16 mt-2">
              Habilidades
            </span>
            <SelectMultiple :items=specialties field="specialties" :validate="v$" placeholder="Selecione suas especialidades" />
          </div>
        </div>

        <div class="d-flex flex-column flex-align-center mt-6 mb-10">
          <button type="submit" class="subscribe-button mt-1">
            <span class="text-white text-bold font-size-16">
              Salvar
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import { reactive } from 'vue'
import { mapActions, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'

import InputText from '@/components/InputText.vue'
import SelectMultiple from '@/components/SelectMultiple.vue'

export default {
  name: 'EditProfileView',
  data: () => ({
    user: {},
    specialties: [],
    interests: [],
    selectedFile: null
  }),
  components: {
    InputText,
    SelectMultiple
  },
  created () {
    this.loadSpecialties()
    this.loadInterests()
    this.getProfile()
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    avatar() {
      if (this.selectedFile) return URL.createObjectURL(this.selectedFile)
      if (this.user.image) return `${process.env.VUE_APP_API_BASE_URL}/uploads/images/users/${this.user.image}`

      return '/images/profile/user.jpg'
    }
  },
  methods: {
    ...mapActions(['setCurrentUser']),
    getProfile() {
      const id = this.currentUser.id

      api.users.get(id).then((response) => {
        const { data:user, status } = response
        if (status === 200) {
          const specialty_ids = []
          const interest_ids = []
          user.specialties.forEach((specialty) => specialty_ids.push(specialty.id))
          user.interests.forEach((interest) => interest_ids.push(interest.id))
          this.user = {
            ...user,
            specialties: specialty_ids,
            interests: interest_ids
          }
          this.updateFormUser()
        }
      })
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      const id = this.$route.params.id
      const image = await this.uploadImage()

      const params = {
        id,
        name: this.v$.name.$model,
        email: this.v$.email.$model,
        instagram: this.v$.instagram.$model,
        specialty_ids: this.v$.specialties.$model,
        interest_ids: this.v$.interests.$model,
        company_name: this.v$.company_name.$model,
        company_branch: this.v$.company_branch.$model,
        company_city: this.v$.company_city.$model,
        image
      }

      api.users.update(params).then((response) => {
        if (response.status === 200) {
          const { data } = response
          this.setCurrentUser(data)
          this.$router.push(`/profile`)
        }
      })
    },
    formatInstagram(input) {
      const value = input.target.value
      if (!value.startsWith('@')) {
        this.v$.instagram.$model = `@${value}`
        return
      }
      this.v$.instagram.$model = value
    },
    updateFormUser() {
      const user = this.user

      this.v$.name.$model = user.name
      this.v$.email.$model = user.email
      this.v$.instagram.$model = user.instagram
      this.v$.specialties.$model = user.specialties
      this.v$.interests.$model = user.interests
      this.v$.company_name.$model = user.company_name
      this.v$.company_branch.$model = user.company_branch
      this.v$.company_city.$model = user.company_city
    },
    loadSpecialties() {
      api.specialties.get().then((response) => {
        const { data:specialties, status } = response
        if (status === 200) {
          this.specialties = specialties
        }
      })
    },
    loadInterests() {
      api.interests.get().then((response) => {
        const { data:interests, status } = response
        if (status === 200) {
          this.interests = interests
        }
      })
    },
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0]
    },
    async uploadImage() {
      if (!this.selectedFile) return

      const formData = new FormData()
      formData.append('image', this.selectedFile)
      formData.append('folder', 'images/users')

      const response = await api.uploads.create(formData)
      return response.data.filename
    },
    handleAvatarClick() {
      this.$refs.fileInput.click()
    }
  },
  setup () {
    const state = reactive({
      name: '',
      email: '',
      instagram: '',
      specialties: [],
      interests: [],
      company_name: '',
      company_branch: '',
      company_city: ''
    })
    const rules = {
      name: {
        required: helpers.withMessage('Preencha o seu nome', required)
      },
      email: {
        required: helpers.withMessage('E-mail não pode ficar em branco', required),
        email: helpers.withMessage('Precisa ser um e-mail válido', email)
      },
      instagram: {},
      specialties: {},
      interests: {},
      company_name: {},
      company_branch: {},
      company_city: {}
    }
    const v$ = useVuelidate(rules, state)
    return { v$ }
  }
}
</script>

<style scoped>

.header-profile {
  margin-top: 40px;
  margin-bottom: 20px;
  min-width: 300px;
  max-width: 65%;
}
.subscribe-button {
  background-color: #288288;
  border: 0px;
  border-radius: 20px;
  padding: 10px;
  width: 80%;
  max-width: 250px;
  font-family: Raleway;
}

.subscribe-button:hover {
  background-color: #227b82;
  cursor: pointer;
}

.card {
  width: 70%;
  min-width: 370px;
  max-width: 500px;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.card form {
  width: 100%;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.user-name {
  text-align: left;
}

.specialties {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.interests {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.company {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.user-avatar {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-right: 10px;
  cursor: pointer;
}

.gradient-top {
  background: linear-gradient(180deg, #f1f1f1 0%, #ffffff 5%);
}
</style>
