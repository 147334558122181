<template>
  <div class="card">
    <div class="btn-edit-profile" v-if="isCurrentUser">
      <a class="text-decoration-none" :href="`/profile/edit`">Editar perfil</a>
    </div>
    <div class="div-avatar">
      <img :src="avatar" class="avatar-image" />
    </div>

    <div class="d-flex flex-column flex-align-center text-center">
      <span class="user-name text-bold">{{ user.name }}</span>
      <span class="font-size-18 font-weight-500">{{ user.company_name }}</span>
      <span  class="font-size-16 mt-3 font-weight-500">{{ user.instagram }}</span>
    </div>

    <div class="div-button">
      <button v-if="!isCurrentUser && showConnectionButton" v-on:click="connect" class="btn-connect">
        SALVAR CONEXÃO 
      </button>
      <button v-if="!isCurrentUser && !showConnectionButton" v-on:click="disconnect" class="btn-disconnect">
        CONEXÃO SALVA 
      </button>
    </div>
    <div class="divider-wave"></div>
    <div class="user-info">
      <!-- <div class="content-interest">
        <div class="col-left">
          <span class="text-bold font-size-20 text-white">Interesses</span>
          <span class="font-size-12 text-white">
            Os itens ao lado são<br />
            uma ótima forma de agregar<br />
            com esse contato
          </span>
        </div>
        <div class="col-right">
          <ul class="margin-0">
            <li v-for="interest in user.interests" :key="interest.name">
              {{ interest.name }}
            </li>
          </ul>
        </div>
      </div> -->
      <!-- <div class="divider"></div> -->
      <div class="content-specialty">
        <div class="col-left">
          <span class="text-bold font-size-20 text-white">Especialidades</span>
          <span class="font-size-12 text-white">
            Esse contato é especializado<br />
            nos assuntos ao lado, fale<br />
            sobre esses assuntos
          </span>
        </div>
        <div class="col-right">
          <ul>
            <li v-for="(specialty, index) in user.specialties" :key="index">
              {{ specialty.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content-data text-white">
      <div class="col-left">
        <ul class="margin-0">
          <li v-if="user.company_name">Empresa</li>
          <li v-if="user.company_branch">Ramo</li>
          <li v-if="user.company_city">Cidade</li>
        </ul>
      </div>
      <div class="col-right">
        <ul class="margin-0 list-decoration-none">
          <li v-if="user.company_name">{{ user.company_name }}</li>
          <li v-if="user.company_branch">{{ user.company_branch }}</li>
          <li v-if="user.company_city">{{ user.company_city }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/api'
export default {
  name: 'ProfileRead',
  data: () => ({
    showConnectionButton: false
  }),
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  created() {
    this.showConnectionButton = !this.user.connected
  },
  watch: {
    user() {
      this.showConnectionButton = !this.user.connected
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    isCurrentUser() {
      return this.user.id === this.currentUser.id
    },
    avatar() {
      if (this.user.image) return `${process.env.VUE_APP_API_BASE_URL}/uploads/images/users/${this.user.image}`

      return '/images/profile/user.jpg'
    }
  },
  methods: {
    connect() {
      const connected_user_id = this.user.id

      api.connections.create({ connected_user_id })
      this.showConnectionButton = false
    },
    disconnect() {
      const connected_user_id = this.user.id

      api.connections.delete({ connected_user_id })
      this.showConnectionButton = true
    }
  }
}
</script>

<style scoped>
.card {
  position: relative;
  width: 70%;
  min-width: 370px;
  max-width: 600px;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

.btn-edit-profile {
  position: absolute;
  right: 10px;
  font-size: 13px;
  text-align: right;
  padding-right: 20px;
  padding-top: 20px;
  color:#0085b8;
  > a {
    color:#0085b8;
    text-decoration: none;
    font-weight: 500;
  }
}

.div-avatar {
  text-align: center;
  margin-top: -60px;
  margin-bottom: 20px;
}

.avatar-image {
  width: 120px;
  height: 120px;
  border-radius: 100%;
}

.div-button {
  text-align: center;
  background-color: #fff;
  height: 40px;
  border-bottom-left-radius: 100% 70px;
  border-bottom-right-radius: 100% 70px;
  transform: translateY(35px);
  margin-top: -30px;
  width: 100%;
}

.div-button button {
  margin-top: 20px;
  background-color: #00e0e4;
  border: 0;
  border-radius: 15px;
  padding: 8px 15px 8px 15px;
  color: #FFF;
  font-weight: bold;
  font-size: 16px;
}

.divider-wave {
  height: 50px;
  background-color: #00bfc2;
}

.user-info {
  display: flex;
  flex-direction: column;
  background-color: #00bfc2;
  margin-top: -1px;
}

.content-interest, .content-specialty, .content-data {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.content-data {
  background-color: #00aaad;
}

.content-data .col-left {
  padding-right: 30px;
}

.content-data .col-left ul li {
  font-weight: bold;
  font-size: 20px;
  text-align: left;
}

.divider {
  height: 3px;
  background-color: #00e0e4;
  width: 60%;
  margin: 0 auto;
}

.col-left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: baseline;
  text-align: end;
  width: 50%;
}

.col-left li {
  list-style-type: none;
}

.col-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: baseline;
  text-align: start;
  width: 50%;
  color: white;
}

.margin-0 {
  margin: 0;
}

.user-name {
  font-size: 32px;
  font-weight: bold;
}

.list-decoration-none {
  list-style-type: none;
  padding-left: 0;
}

.list-decoration-none li {
  height: 23px;
}
</style>