<template>
  <div class="d-flex flex-column flex-align-center text-center bg pt-10">
    <div class="d-flex flex-column flex-align-center w-100">
      <div class="logo"></div>
      <span class="text-secondary text-bold font-size-16 mt-4">Salve seus contatos</span>
      <span class="text-primary text-bold font-size-16">com inteligência.</span>
      <form @submit.prevent="submitForm" class="recovery mt-4">
        <div class="user-info">
          <InputText field="password" placeholder="Nova senha" :validate="v$" :required="true" type="password" bgColor="#e9e9e9" />
          <InputText field="password_confirmation" placeholder="Confirmar nova senha" :validate="v$" :required="true" type="password" bgColor="#e9e9e9" />
        </div>
        <div class="d-flex flex-column flex-align-center mt-6 mb-10">
          <button type="submit" class="recovery-button mt-1">
            <span class="text-bold font-size-16">
              ALTERAR SENHA
            </span>
          </button>
          <router-link to="/sign-in" class="font-size-14 mt-5 font-weight-500 text-default text-decoration-none">Voltar para o login</router-link>
          <router-link to="/sign-up" class="font-size-14 mt-5 font-weight-500 text-default text-decoration-none">Não tem uma conta? Crie uma</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import InputText from '@/components/InputText'
import api from '@/services/api'

export default {
  name: 'UpdatePasswordView',
  components: {
    InputText
  },
  data() {
    return {
      password: '',
      password_confirmation: ''
    }
  },
  created() {
    if (this.currentUser) {
      this.$router.push('/connections')
    }
  },
  methods: {
    async submitForm() {
      try {
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return

        const params = {
          user: {
            password: this.v$.password.$model,
            password_confirmation: this.v$.password_confirmation.$model,
            reset_password_token: this.$route.query.reset_password_token
          }
        }

        const response = await api.auth.updatePassword(params)

        if (response.status === 200) {
          window.location.href = 'nodetracker://login'
          this.$router.push('/sign-in')
        } else {
          alert('Erro ao alterar senha, entre em contato com o suporte')
          console.error('Erro ao alterar senha:', response)
        }
      } catch (error) {
        alert('Erro ao alterar senha, entre em contato com o suporte')
        console.error('Erro ao alterar senha:', error)
      }
    }
  },
  setup () {
    const state = reactive({
      password: '',
      password_confirmation: ''
    })
    const rules = {
      password: {
        required: helpers.withMessage('Senha não pode ficar em branco', required),
        minLength: helpers.withMessage('A senha precisa ter no mínimo 6 caracteres', value => value.length >= 6)
      },
      password_confirmation: {
        required: helpers.withMessage('Confirmação de senha não pode ficar em branco', required)
      }
    }

    const v$ = useVuelidate(rules, state)

    return { state, v$ }
  }
}
</script>

<style scoped>
.recovery {
  width: 100%;
  max-width: 400px;
}

.bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  width: 100vw;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
}

.recovery-button {
  background-color: #288288;
  color: #FFF;
  border: 0px;
  border-radius: 20px;
  padding: 10px;
  width: 60%;
  min-width: 225px;
  max-width: 250px;
  font-family: Raleway;
}

.logo {
  background: url(../../assets/logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
}
</style>