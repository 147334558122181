<template>
  <div class="d-flex flex-column flex-align-center full-screen-height text-center bg pt-10">
    <div class="d-flex flex-column flex-align-center w-100">
      <img
        src="../../assets/logo-white.svg"
        alt="Tria."
        width="70"
        height="70"
      />
      <span class="text-white text-bold font-size-16 mt-4">Salve seus contatos</span>
      <span class="text-primary text-bold font-size-16">com inteligência.</span>
      <span class="text-white text-bold font-size-16 mt-10">
        Informações para um networking inteligente
      </span>
      <form @submit.prevent="subscribe" class="subscribe mt-4" v-if="!subscribed">
        <div class="user-info">
          <span class="text-white text-bold font-size-16 mt-2">
            Informações pessoais
          </span>
          <InputText field="name" placeholder="Seu nome completo" :validate="v$" :required="true" type="text" />
          <InputText field="email" placeholder="Seu e-mail" :validate="v$" :required="true" type="email" />
          <InputText field="instagram" placeholder="Seu @ no Instagram" :validate="v$" :required="true" type="text" />
        </div>
        
        <div class="specialties">
          <div>
            <span class="text-white font-size-16 mt-2">
              Suas Principais
            </span>
            <span class="text-white text-bold font-size-16 mt-2">
              Especialidades
            </span>
            <SelectMultiple :items=specialties field="specialties" :validate="v$" placeholder="Selecione suas especialidades" />
          </div>
        </div>

        <div class="interests">
          <div>
            <span class="text-white font-size-16 mt-2">
              Seus Principais
            </span>
            <span class="text-white text-bold font-size-16 mt-2">
              Interesses
            </span>
            <SelectMultiple :items=interests field="interests" :validate="v$" placeholder="Selecione seus interesses" />
          </div>
        </div>

        <div class="company">
          <div>
            <span class="text-white font-size-16 mt-2">
              Sobre a sua
            </span>
            <span class="text-white text-bold font-size-16 mt-2">
              Empresa
            </span>
            <InputText field="company_name" placeholder="Empresa em que você atua" :validate="v$" :required="true" type="text" />
            <InputText field="company_branch" placeholder="Ramo de atuação" :validate="v$" :required="true" type="text" />
            <InputText field="company_city" placeholder="Cidade de principal atuação" :validate="v$" :required="true" type="text" />
          </div>
        </div>
        <div class="d-flex flex-column flex-align-center mt-6 mb-10">
          <button type="submit" class="subscribe-button mt-1">
            <span class="text-white text-bold font-size-16">
              Fazer pré-cadastro
            </span>
          </button>
        </div>
      </form>
      <div v-else class="mt-4">
        <p class="text-white text-bold">Pré-cadastro realizado com sucesso!</p>
        <p class="text-white">Em breve você receberá um e-mail com mais informações.</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import InputText from './components/InputText'
import SelectMultiple from './components/SelectMultiple'

export default {
  name: 'SubscribeView',
  components: {
    InputText,
    SelectMultiple
  },
  data() {
    return {
      subscribed: false,
      specialties: [],
      interests: []
    }
  },
  async created() {
    await this.loadSpecialties()
    await this.loadInterests()
  },
  computed: {
    uuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    }
  },
  methods: {
    async subscribe() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      const params = {
        name: this.v$.name.$model,
        email: this.v$.email.$model,
        password: this.uuid,
        instagram: this.v$.instagram.$model,
        specialty_ids: this.v$.specialties.$model,
        interest_ids: this.v$.interests.$model,
        company_name: this.v$.company_name.$model,
        company_city: this.v$.company_city.$model,
        company_branch: this.v$.company_branch.$model
      }

      api.auth.signUp(params).then((response) => {
        if (response.status === 200) {
          this.subscribed = true
        }
      })
    },
    loadSpecialties() {
      api.specialties.get().then((response) => {
        const { data:specialties, status } = response
        if (status === 200) {
          this.specialties = specialties
        }
      })
    },
    loadInterests() {
      api.interests.get().then((response) => {
        const { data:interests, status } = response
        if (status === 200) {
          this.interests = interests
        }
      })
    }
  },
  setup () {
    const state = reactive({
      name: '',
      email: '',
      instagram: '',
      specialties: [],
      interests: [],
      company_name: '',
      company_city: '',
      company_branch: ''
    })
    const rules = {
      name: {
        required: helpers.withMessage('Preencha o seu nome', required)
      },
      email: {
        required: helpers.withMessage('E-mail não pode ficar em branco', required),
        email: helpers.withMessage('Precisa ser um e-mail válido', email)
      },
      instagram: {},
      specialties: {},
      interests: {},
      company_name: {},
      company_branch: {},
      company_city: {}
    }
    const v$ = useVuelidate(rules, state)
    return { v$ }
  }
}
</script>

<style scoped>
.subscribe-button {
  background-color: #288288;
  border: 0px;
  border-radius: 20px;
  padding: 10px;
  width: 80%;
  max-width: 250px;
  font-family: Raleway;
}

.subscribe-button:hover {
  background-color: #227b82;
  cursor: pointer;
}

.subscribe {
  width: 80%;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.specialties {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.interests {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.company {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.bg {
  background: url(../../assets/images/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  width: 100vw;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
}
</style>
