import { createRouter, createWebHistory } from 'vue-router'

import InitialView from '@/views/InitialView'
import SignInView from '@/views/auth/SignInView'
import SignUpView from '@/views/auth/SignUpView'
import ConnectionsView from '@/views/connections/ConnectionsView'
import ConnectionView from '@/views/connections/ConnectionView'
import CommunitiesView from '@/views/communities/CommunitiesView'
import CommunityView from '@/views/communities/CommunityView'
import ProfileView from '@/views/profile/ProfileView'
import EditProfileView from '@/views/profile/EditProfileView'
import SubscribeView from '@/views/subscribe/SubscribeView'
import ForgotPasswordView from '@/views/auth/ForgotPasswordView'
import UpdatePasswordView from '@/views/auth/UpdatePasswordView'

const routes = [
  {
    path: '/',
    name: 'initial',
    component: InitialView
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignInView
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUpView
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordView
  },
  {
    path: '/update-password',
    name: 'update-password',
    component: UpdatePasswordView
  },
  {
    path: '/connections',
    name: 'connections',
    component: ConnectionsView
  },
  {
    path: '/connections/:id',
    name: 'connection',
    component: ConnectionView
  },
  {
    path: '/communities',
    name: 'communities',
    component: CommunitiesView
  },
  {
    path: '/communities/:id',
    name: 'community',
    component: CommunityView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/profile/edit',
    name: 'profile_edit',
    component: EditProfileView
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: SubscribeView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router