<template>
  <div class="content-connection">
    <ProfileRead :user="connection" />
  </div>
</template>
 
<script>
import ProfileRead from '@/components/ProfileRead'
import api from '@/services/api'
export default {
  name: 'ConnectionView',
  components: {
    ProfileRead
  },
  data() {
    return {
      connection: {}
    }
  },
  async created() {
    await this.getConnection()
  },
  methods: {
    getConnection() {
      const { id } = this.$route.params

      api.connections.getById(id).then(({ data }) => {
        this.connection = data
      })
    }
  }
};
 </script>
 
 <style scoped>
.content-connection {
  display: flex;
  flex-direction: column;
  align-items:center;
  margin-top: 40px;
}
</style>