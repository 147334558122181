export default (api) => ({
  signIn: (data) => {
    return api.post(
      `/users/sign_in`,
      {
        email: data.email,
        password: data.password
      }
    )
  },
  signUp: (params) => {
    return api.post(
      `/users`,
      params
    )
  },
  forgotPassword: (params) => {
    return api.post(
      `/forgot_password`,
      params
    )
  },
  updatePassword: (params) => {
    return api.put(
      `/users/password`,
      params
    )
  },
})