<template>
  <input type="text" v-model="search" placeholder="Pesquisar Conexões" class="search-input icon-connections-flat" />
  <div class="user-list">
    <router-link
      v-for="user in usersFiltered"
      :to="'/connections/' + user.id"
      :key="user.id"
      class="user"
    >
      <div class="user-details">
        <div class="user-info">
          <div class="user-avatar">
            <img :src="getAvatar(user)" alt="Avatar" />
          </div>
          <div>
            <div class="user-name">
              {{ user.name }}
            </div>
            <div class="user-sub-info">
              <!-- <div class="user-city">
                {{ user.city }}
              </div> -->
              <div class="user-instagram">
                {{ user.instagram }}
              </div>
            </div>
            <div class="specialties-list">
              <span class="specialty-item" v-for="(specialty, index) in user.specialties" :key="index">
                {{ specialty.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ListUsers',
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    usersFiltered() {
      return this.users.filter((user) => {
        if (this.search) {
          return user.name.toLowerCase().startsWith(this.search.toLowerCase())
        }

        return true
      })
    }
  },
  methods: {
    getAvatar (user) {
      if (user.image) return `${process.env.VUE_APP_API_BASE_URL}/uploads/images/users/${user.image}`

      return '/images/profile/user.jpg'
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000;
}

.user-list {
  margin: 0 auto;
  padding-bottom: 40px;
  width: 95%;
}

.user {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-top: 1px;
}

.user-avatar {
  display: flex;
  align-items: center;
}

.user-avatar img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.user-info {
  display: flex;
  flex-direction: row;
}

.user-details {
  display: flex;
  flex-direction: row;
  min-width: 40%;
}

.user-name {
  font-size: 16px;
}

.user-sub-info {
  display: flex;
  font-size: 14px;
}

.specialties-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.specialty-item {
  font-size: 14px;
  padding: 4px;
  margin-right: 5px;
  border: 1px solid #CCC;
  border-radius: 5px;
  margin-bottom: 5px;
}
</style>