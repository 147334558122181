<template>
  <div class="d-flex flex-column flex-align-center mt-4">
    <h2>
      {{ community.name }}
    </h2>
    <ListUsers :users="users" />
  </div>
</template>

<script>
import ListUsers from '@/components/ListUsers'
import api from '@/services/api'
export default {
  name: 'CommunityView',
  components: {
    ListUsers
  },
  data: () => ({
    community: {},
    users: [],
    search: ''
  }),
  created() {
    this.getCommunity()
  },
  methods: {
    getCommunity() {
      const id = this.$route.params.id

      api.communities.getById(id).then(({ data:community }) => {
        this.community = community
        this.users = community.users
      })
    }
  }
}
</script>

<style scoped>
.contact-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 20px;
}

.contact {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-avatar img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.contact-details {
  display: flex;
  flex-direction: column;
}

.contact-name {
  font-size: 16px;
}

.contact-email {
  font-size: 14px;
  color: #666;
}
</style>