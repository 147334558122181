<template>
  <div class="bg">
    <div class="container">
      <div class="initial-view">
        <div class="logo"></div>
        <span class="text-white text-bold font-size-16 mt-4">Salve seus contatos</span>
        <span class="text-primary text-bold font-size-16">com inteligência.</span>
        <div class="buttons">
          <router-link to="/sign-up" class="btn btn-primary text-center font-weight-500 text-decoration-none">CRIAR UMA CONTA</router-link>
          <router-link to="/sign-in" class="btn btn-secondary text-center font-weight-500 text-decoration-none">ENTRAR</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'InitialView',
  created() {
    if (this.currentUser) {
      this.$router.push('/connections')
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  }
}
</script>

<style scoped>
.bg {
  background: url(../assets/images/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-width: 100vw;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.initial-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
}

.logo {
  background: url(../assets/logo-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 35%;
  min-width: 180px;
  max-width: 270px;
}

.btn {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 25px;
  padding: 15px 20px 15px 20px;
  font-family: Raleway;
  font-weight: bold;
  color: #fff;
  font-size: 14px;
}

.btn-primary {
  background-color: #288288;
  border: 0px;
}

.btn-secondary {
  background-color: transparent;
  border: 1px solid #FFF;
}
</style>