<template>
  <ListUsers :users="users" />
</template>

<script>
import api from '@/services/api'
import ListUsers from '@/components/ListUsers'
export default {
  name: 'ConnectionsView',
  components: {
    ListUsers
  },
  data() {
    return {
      search: '',
      users: []
    }
  },
  created() {
    this.getConnections()
  },
  methods: {
    getConnections() {
      api.connections.get().then(({ data }) => {
        this.users = data
      })
    }
  }
}
</script>
