const state = {
  currentUser: null,
}

const mutations = {
  SET_CURRENT_USER(state, user) {
    state.currentUser = user
  },
  LOGOUT(state) {
    state.currentUser = null
  },
}

const actions = {
  setCurrentUser({ commit }, user) {
    commit('SET_CURRENT_USER', user)
  },
  logout({ commit }, { callback }) {
    commit('LOGOUT')
    callback()
  },
}

export default {
  namespaced: true, // Define o namespace como true para usar o namespace 'auth'
  state,
  mutations,
  actions,
}
