<template>
  <div class="centered-container">
    <div class="input-wrapper">
      <div class="select-container">
        <div class="selected-options" @click="toggleOptions">
          <span v-if="selectedOptions.length === 0">{{ placeholder }}</span>
          <span v-else>{{ selectedNames }}</span>
        </div>
        <ul class="options" v-show="showOptions">
          <li v-for="item in items" :key="item.id" @click="toggleSelection(item.id)" :class="{ 'selected': isSelected(item.id) }">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showOptions: false,
      selectedOptions: []
    };
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    validate: {
      type: Object,
      required: true
    }
  },
  created() {
    this.v = this.validate;
  },
  computed: {
    selectedNames() {
      return this.selectedOptions.map(id => this.getItemName(id)).join(', ');
    }
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    toggleSelection(id) {
      const index = this.selectedOptions.indexOf(id);
      if (index === -1) {
        this.selectedOptions.push(id);
      } else {
        this.selectedOptions.splice(index, 1);
      }
      this.v[this.field].$model = this.selectedOptions;
    },
    isSelected(id) {
      return this.selectedOptions.includes(id);
    },
    getItemName(id) {
      const item = this.items.find(item => item.id === id);
      return item ? item.name : '';
    }
  }
};
</script>

<style scoped>
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.input-wrapper {
  cursor: pointer;
  width: 95%;
  max-width: 320px;
}

.select-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.selected-options {
  line-height: 20px;
  border: 0px;
  border-radius: 20px;
  background-color: #1a2c43b5;
  color: #CCC;
  padding: 10px;
  text-align: center;
  font-family: 'Raleway';
  font-size: 16px;
  width: 100%;
}

.options {
  list-style-type: none;
  padding: 0;
  position: absolute;
  top: calc(100% - 13px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background-color: #1a2c43;
  border-radius: 20px;
  width: 95%;
  max-height: 320px;
  overflow-y: auto;
  display: none;
  color: #FFF;
}

.options li {
  padding: 10px;
  cursor: pointer;
}

.options li.selected {
  background-color: #35495eaf;
  color: white;
}

.input-wrapper:hover .options {
  display: block;
}
</style>
