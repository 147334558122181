<template>
  <input type="text" v-model="search" placeholder="Pesquisar Comunidades" class="search-input icon-communities-flat" />
  <div class="community-list">
    <router-link
      v-for="community in communitiesFiltered"
      :to="'/communities/' + community.id"
      :key="community.id"
      class="community"
    >
      <div class="community-info">
        <div class="community-image">
          <img :src="getImage(community)" alt="Community" />
        </div>
        <div class="community-content">
          <div class="community-name">{{ community.name }}</div>
          <div class="community-data-second-row">
            <div class="community-city">{{ community.city }}</div>
            <div class="count-participants">
              <div class="icon-participants"></div>
              {{ community.count }}
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ListCommunities',
  data () {
    return {
      search: ''
    }
  },
  props: {
    communities: {
      type: Array,
      required: true
    }
  },
  computed: {
    communitiesFiltered() {
      return this.communities.filter(community => {
        return community.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    getImage (community) {
      if (community.image) return `${process.env.VUE_APP_API_BASE_URL}/uploads/images/communities/${community.image}`
      
      return require('../assets/images/community1.jpg')
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000;
}

.community-list {
  margin: 0 auto;
  width: 80%;
  border-left: 2px solid #e7e7e7;
  padding-left: 15px;
}

.community {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-top: 1px;
  padding-right: 15px;
}

.community-image {
  display: flex;
  align-items: center;
}

.community-image img {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 80px;
  height: 50px;
  margin-right: 10px;
}

.community-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.community-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.icon-participants {
  width: 13px;
  height: 13px;
  background-image: url(../assets/icons/connections-flat.svg);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 5px;
}

.community-data-second-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.community-name {
  font-size: 16px;
  font-weight: bold;
}

.community-city {
  font-size: 14px;
}

.count-participants {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

</style>