<template>
  <ListCommunities :communities="communities" />
</template>

<script>
import ListCommunities from '@/components/ListCommunities'
import api from '@/services/api'
export default {
  name: 'CommunitiesView',
  components: {
    ListCommunities
  },
  data() {
    return {
      communities: []
    }
  },
  created() {
    this.getCommunities()
  },
  methods: {
    getCommunities() {
      api.communities.get().then(({ data }) => {
        this.communities = data
      })
    }
  }
}
</script>
