<template>
  <div :class="isLogged ? 'app-content' : ''">
    <router-view />
    <div class="menu" v-if="isLogged">
      <div class="menu-content">
        <router-link
          v-for="item in menu"
          :to="item.route"
          :key="item.name"
          class="option"
          v-on:click="scrollPage"
        >
          <div class="icon-menu" :class="getIcon(item)"></div>
          <span class="menu-item" :class="setBoldIsActive(item)">{{ item.name }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'
import { mapState } from 'vuex'
export default {
  name: 'App',
  data() {
    return {
      page: null,
      isLogged: !!this.currentUser,
      menu: [
        {
          name: 'Conexões',
          icon: 'connections',
          route: '/connections'
        },
        {
          name: 'Comunidades',
          icon: 'communities',
          route: '/communities'
        },
        {
          name: 'Perfil',
          icon: 'profile',
          route: '/profile'
        }
      ]
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  watch: {
    $route(to) {
      this.page = to.name
      this.isLogged = !!this.currentUser
    }
  },
  methods: {
    getIcon(item) {
      if (this.isActive(item)) {
        return `icon-${item.icon}-bold`
      }
      return `icon-${item.icon}-flat`
    },
    setBoldIsActive(item) {
      if (this.isActive(item)) {
        return 'text-bold'
      }
    },
    isActive(item) {
      return location.pathname.indexOf(item.route) > -1
    },
    scrollPage() {
      window.scrollTo(0, 0)
    }
  },
  setup () {
    useHead({
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1'
        }
      ]
    })
  }
}
</script>

<style>
@font-face {
  font-family: Raleway;
  src: url(assets/fonts/Raleway.ttf);
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0px !important;
  font-family: Raleway;
  color: #646464;
}

.app-content {
  margin-bottom: 80px;
}

.icon-connections-flat {
  background: url(assets/icons/connections-flat.svg);
}

.icon-communities-flat {
  background: url(assets/icons/communities-flat.svg);
}

.icon-profile-flat {
  background: url(assets/icons/profile-flat.svg);
}

.icon-connections-bold {
  background: url(assets/icons/connections-bold.svg);
}

.icon-communities-bold {
  background: url(assets/icons/communities-bold.svg);
}

.icon-profile-bold {
  background: url(assets/icons/profile-bold.svg);
}

.icon-save-filled {
  background-image: url(assets/icons/save-filled.svg);
}

.icon-save-outlined {
  background-image: url(assets/icons/save-outlined.svg);
}

.icon-menu {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
}

.menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
}

.menu-content {
  display: flex;
  width: 80%;
}

.menu-item {
  font-size: 14px;
}

.option {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333;
  cursor: pointer;
  height: 100%;
  text-decoration: none;
}

.option span {
  margin-top: 5px;
}

.text-bold {
  font-weight: bold;
}

.d-flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-column {
  flex-direction: column;
}

.error {
  background-color: red;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #fff;
}

.text-default {
  color: #646464;
}

.text-primary {
  color: #37afb6;
}

.text-secondary {
  color: #005a59;
}

.text-bold {
  font-weight: bold;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-24 {
  font-size: 24px;
}

.font-weight-500 {
  font-weight: 500;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-5 {
  margin-top: 20px;
}

.mt-6 {
  margin-top: 24px;
}

.mt-7 {
  margin-top: 28px;
}

.mt-8 {
  margin-top: 32px;
}

.mt-9 {
  margin-top: 36px;
}

.mt-10 {
  margin-top: 40px;
}

.mb-10 {
  margin-bottom: 40px;
}

.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.pt-10 {
  padding-top: 40px;
}

.w-70 {
  width: 70%;
}

.w-100 {
  width: 100%;
}

.search-input {
  display: block;
  width: 60%;
  height: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin: 0 auto;
  margin-top: 20px;
  padding: 10px 10px 10px 40px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 10px center;
}

.text-decoration-none {
  text-decoration: none;
}
</style>
