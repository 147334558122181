<template>
  <div class="d-flex flex-column flex-align-center text-center bg pt-10 signup">
    <div class="d-flex flex-column flex-align-center w-100">
      <img
        src="../../assets/logo-white.svg"
        alt="Tria."
        width="70"
        height="70"
      />
      <span class="text-white text-bold font-size-16 mt-4">Salve seus contatos</span>
      <span class="text-primary text-bold font-size-16">com inteligência.</span>
      <span class="text-white text-bold font-size-16 mt-10">
        Informações para um networking inteligente
      </span>
      <form @submit.prevent="submitForm" class="subscribe mt-4">
        <div class="user-info">
          <span class="text-white text-bold font-size-16 mt-2">
            Informações pessoais
          </span>
          <InputText field="nickname" placeholder="Escolha seu .node" :validate="v$" :required="true" type="text" bgColor="#1a2c43" />
          <InputText field="name" placeholder="Seu nome completo" :validate="v$" :required="true" type="text" bgColor="#1a2c43" />
          <InputText field="email" placeholder="Seu e-mail" :validate="v$" :required="true" type="email" bgColor="#1a2c43" />
          <InputText field="password" placeholder="Senha" :validate="v$" :required="true" type="password" bgColor="#1a2c43" />
          <InputText field="confirmPassword" placeholder="Confirmar senha" :validate="v$" :required="true" type="password" bgColor="#1a2c43" />
        </div>

        <div class="d-flex flex-column flex-align-center mt-6 mb-10">
          <button type="submit" class="subscribe-button mt-1">
            <span class="text-white text-bold font-size-16">
              CADASTRAR
            </span>
          </button>
          <router-link to="/sign-in" class="text-white font-size-14 mt-5 font-weight-500 text-decoration-none">Já tem uma conta? Faça login</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { mapActions, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import InputText from '@/components/InputText'
import api, { onSignin } from '@/services/api'

export default {
  name: 'SignUpView',
  components: {
    InputText
  },
  created() {
    if (this.currentUser) {
      this.$router.push('/connections')
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser']),
    async submitForm() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      if (this.password === this.confirmPassword) {
        this.submit()
      } else {
        alert('As senhas não correspondem.')
      }
    },
    async submit() {
      const params = {
        nickname: this.v$.nickname.$model,
        name: this.v$.name.$model,
        email: this.v$.email.$model,
        password: this.v$.password.$model
      }

      api.auth.signUp(params).then(response => {
        if (response.status === 200) {
          const { data } = response.data
          onSignin(response)
          this.setCurrentUser(data)
          this.$router.push('/connections')
        } else {
          alert('Ocorreu um erro ao cadastrar o usuário.')
        }
      }).catch(err => {
        console.log(err.response)
        if (err.response.data.errors['email']) {
          alert('E-mail já cadastrado ou inválido')
        } else if (err.response.data.errors['nickname']) {
          alert('.Node já cadastrado ou inválido')
        } else {
          alert('Ocorreu um erro ao cadastrar o usuário.')
        }
      })
    }
  },
  setup () {
    const state = reactive({
      nickname: '',
      name: '',
      email: '',
      password: '',
      confirmPassword: ''
    })
    const rules = {
      nickname: {
        required: helpers.withMessage('Escolha um .node', required)
      },
      name: {
        required: helpers.withMessage('Preencha o seu nome', required)
      },
      email: {
        required: helpers.withMessage('E-mail não pode ficar em branco', required),
        email: helpers.withMessage('Precisa ser um e-mail válido', email)
      },
      password: {
        required: helpers.withMessage('Senha não pode ficar em branco', required),
        minLength: helpers.withMessage('A senha precisa ter no mínimo 6 caracteres', value => value.length >= 6)
      },
      confirmPassword: {
        required: helpers.withMessage('Confirmação de senha não pode ficar em branco', required)
      }
    }
    const v$ = useVuelidate(rules, state)
    return { v$ }
  }
}
</script>

<style scoped>
input {
  color: #FFF;
}

.bg {
  background: url(../../assets/images/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  width: 100vw;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
}

.subscribe-button {
  background-color: #288288;
  border: 0px;
  border-radius: 20px;
  padding: 10px;
  width: 80%;
  max-width: 250px;
  font-family: Raleway;
}

.subscribe-button:hover {
  background-color: #227b82;
  cursor: pointer;
}

.subscribe {
  width: 80%;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.specialties {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.interests {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.company {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

</style>
